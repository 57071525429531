import React from 'reactn';
import useSWR from 'swr';

import SummaryCard from '../../components/card/summary-card/summary-card.component';
import { TrendingUp, PieChart, Users } from 'react-feather';
import { withRouter } from 'react-router';
import { formatCurrency } from '../../utils/common.util';
import { useEffect, useState } from 'react';

const SummaryPane = (props) => {
  const [revalidate, setRevalidate] = useState(true);

  const { data: revenue } = useSWR(`/dashboard/monthly-stats/revenue`, {
    revalidateOnFocus: false,
    revalidateOnMount: revalidate,
    refreshInterval: 1800000, //30 minutes
  });

  const { data: expenditure } = useSWR(`/dashboard/monthly-stats/expenditure`, {
    revalidateOnFocus: false,
    revalidateOnMount: revalidate,
    refreshInterval: 1800000, //30 minutes
  });

  const { data: students } = useSWR(`/dashboard/students/total-enrolled`, {
    revalidateOnFocus: false,
    revalidateOnMount: revalidate,
    refreshInterval: 1800000, //30 minutes
  });

  const { data: totalBalanceDue } = useSWR(`/dashboard/students/amount-owed`, {
    revalidateOnFocus: false,
    revalidateOnMount: revalidate,
    refreshInterval: 1800000, //30 minutes
  });

  return (
    <div>
      {props.currentUser.identityRoles[0] === 'SchoolAdministrator' && (
        <div>
          <h3 className="text-dark text-24 font-bold">At a glance</h3>
          <p className="text-14 text-grey mt-10">
            Summary of some metrics over the months
          </p>

          <div className="bx--row mt-10">
            <div className="bx--col-md-2 fade-in">
              <SummaryCard
                description="Cash Received this Month"
                iconBackgroundColor="green"
                transform={true}
                value={
                  revenue
                    ? formatCurrency(revenue.totalRevenuesForTheMonth)
                    : '...'
                }
                leftAction={props.onAddCashReceive}
                rightActionText="GENERATE REPORT"
                rightAction={() =>
                  props.history.push('analytics/cash-received')
                }>
                <TrendingUp color="#fff" />
              </SummaryCard>
            </div>

            <div className="bx--col-md-2 fade-in">
              <SummaryCard
                // description="Expenses this Month"
                description="Cash Paid This Month"
                value={
                  expenditure
                    ? formatCurrency(expenditure.totalExpenditureForTheMonth)
                    : '...'
                }
                iconBackgroundColor="red"
                leftAction={props.onAddExpenses}
                rightActionText="GENERATE REPORT"
                rightAction={() => props.history.push('analytics/cash-spent')}>
                <TrendingUp color="#fff" />
              </SummaryCard>
            </div>

            <div className="bx--col-md-2 fade-in">
              <SummaryCard
                description="Amount owed by students"
                value={
                  totalBalanceDue
                    ? formatCurrency(totalBalanceDue.totalStudentsBalanceDue)
                    : '...'
                }
                iconBackgroundColor="orange"
                leftActionText="VIEW"
                dangerValue={totalBalanceDue ? true : false}
                leftAction={() => props.history.push('student/?in_debt=True')}
                rightActionText="GENERATE REPORT"
                rightAction={() =>
                  props.history.push('analytics/debtors-list')
                }>
                <PieChart color="#fff" />
              </SummaryCard>
            </div>

            <div className="bx--col-md-2 fade-in">
              <SummaryCard
                description="Students enrolled"
                value={students ? students.totalEnrolledStudents : '...'}
                hideRightAction={true}
                addColor={true}
                leftActionText="VIEW"
                leftAction={() => props.history.push('/student')}>
                <Users />
              </SummaryCard>
            </div>
          </div>

          {/* <LoaderPane loading={isValidating} */}
          {/* error={!data && error?.message && "Summary failed to update. " } onReload={()=>{ mutate()}}/>*/}
          {/*{(!isValidating) && <div className="text-primary font-bold text-center pointer" onClick={()=>{mutate() }}>Refresh summary</div>}*/}

          <hr className="grey-hr mt-20" />
        </div>
      )}
    </div>
  );
};

export default withRouter(SummaryPane);
